import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Carousel } from 'react-bootstrap';
import './Home.css';
import musculacaoImage from '../../static/img/musculacao.png';
import musculacaoImage2 from '../../static/img/img_musc_2.jpg';
import musculacaoImage3 from '../../static/img/img_musc_3.jpg';
import piscinaImage from '../../static/img/img_natacao_1.jpg';
import piscinaImage2 from '../../static/img/img_natacao_2.jpg';
import piscinaImage3 from '../../static/img/img_natacao_3.jpg';
import lutasImage from '../../static/img/img_alliance_1.jpg';
import lutasImage2 from '../../static/img/img_alliance_2.jpg';
import lutasImage3 from '../../static/img/img_alliance_3.jpg';

function Home() {
  return (
    <Container id="home">
      <div className="row">
        <h1 className="title">Nossa galeria</h1>
        <Carousel className="carousel">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={musculacaoImage}
              alt="Primeira imagem"
            />
            <Carousel.Caption>
              <h3>Treino de Força</h3>
              <p>Construa músculos e aumente sua resistência.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={piscinaImage}
              alt="Segunda imagem"
            />
            <Carousel.Caption>
              <h3>Treino Aquático</h3>
              <p>Mantenha seu coração saudável com exercícios intensos.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={lutasImage}
              alt="Terceira imagem"
            />
            <Carousel.Caption>
              <h3>Lutas</h3>
              <p>Melhore sua confiança e força com nossas artes marciais.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={musculacaoImage2}
              alt="Quarta imagem"
            />
            <Carousel.Caption>
              <h3>Treino de Força</h3>
              <p>Construa músculos e aumente sua resistência.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={piscinaImage2}
              alt="Quinta imagem"
            />
            <Carousel.Caption>
              <h3>Treino Aquático</h3>
              <p>Mantenha seu coração saudável com exercícios intensos.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={lutasImage2}
              alt="Sexta imagem"
            />
            <Carousel.Caption>
              <h3>Lutas</h3>
              <p>Melhore sua confiança e força com nossas artes marciais.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={musculacaoImage3}
              alt="Setima imagem"
            />
            <Carousel.Caption>
              <h3>Treino de Força</h3>
              <p>Construa músculos e aumente sua resistência.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={piscinaImage3}
              alt="Oitava imagem"
            />
            <Carousel.Caption>
              <h3>Treino Aquático</h3>
              <p>Mantenha seu coração saudável com exercícios intensos.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={lutasImage3}
              alt="Nona imagem"
            />
            <Carousel.Caption>
              <h3>Lutas</h3>
              <p>Melhore sua confiança e força com nossas artes marciais.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </Container>
  );
}

export default Home;