import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
// import { FaWhatsapp } from 'react-icons/fa';
import './Convenios.css';
import gympassLogo from '../../static/img/gympass.webp';
import totalpassLogo from '../../static/img/totalpass.png';
import classpassLogo from '../../static/img/classpass.jpg';
import allianceBjj from '../../static/img/alliance.png';

function Convenios() {
  const whatsappNumbers = "5511989865875"; // Deixei apenas o contato da unidade da saúde, essa possuí todas as modalidades
  // const randomNumber = whatsappNumbers[Math.floor(Math.random() * whatsappNumbers.length)];
  
  const convenios = [
    {
      name: 'Gympass',
      description: 'Venha treinar conosco utilizando seu Gympass',
      logo: gympassLogo,
    },
    {
      name: 'Totalpass',
      description: 'Venha treinar conosco utilizando seu Totalpass',
      logo: totalpassLogo,
    },
    {
      name: 'Classpass',
      description: 'Venha treinar conosco utilizando seu Classpass',
      logo: classpassLogo,
    },
    {
      name: 'Alliance Jiu Jitsu',
      description: 'Venha conhecer o melhor da arte suave',
      logo: allianceBjj,
    },
    
  ];

  return (
    <Container id="convenios">
      <div className="section-title">
        <h2>Parceiros</h2>
        <p>Conheça os nossos parceiros e aproveite os benefícios!</p>
      </div>
      <Row className="justify-content-center">
        {convenios.map((convenio, index) => (
          <Col md={3} key={index}>
            <Card className="convenio-card">
              <Card.Img variant="top" src={convenio.logo} alt={convenio.name} />
              <Card.Body>
                <Card.Title>{convenio.name}</Card.Title>
                <Card.Text className="card-description">
                  {convenio.description}
                </Card.Text>
                <a href={`https://wa.me/${whatsappNumbers}?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20o%20${convenio.name}.`} className="btn-saiba-mais">
                  Saiba Mais
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Convenios;