import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav } from 'react-bootstrap';
import './Header.css'; 
import logoRubel from '../../static/img/Logo_Rube_Academia.jpeg';
import bottomImage from '../../static/img/initImageTeste.PNG';

function Header() {
  return (
    <div className="header-container">
      <Navbar className="header-navbar" expand="lg">
        <Container>
          <Navbar.Brand href="#" className="navbar-brand">
            <img
              src={logoRubel}
              alt="Rubel Academia"
              className="navbar-logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto navbar-nav">
              <Nav.Link href="#">Home</Nav.Link>
              <Nav.Link href="#sobre">Sobre</Nav.Link>
              <Nav.Link href="#treino">Nossos treinos</Nav.Link>
              <Nav.Link href="#home">Nossa Galeria</Nav.Link>
              <Nav.Link href="#convenios">Convênios</Nav.Link>
              <Nav.Link href="#contatos">Contatos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="bottom-image-container">
        <img
          src={bottomImage}
          alt="Imagem abaixo do header"
          className="bottom-image"
        />
      </div>
    </div>
  );
}

export default Header;