import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import './Contatos.css';

function Contatos() {
  const whatsappSaude = '5511989865875'
  const whatsappCampoBelo = '5511989809250'
  return (
    <Container id="contatos" className="contatos-container mb-5 mt-5">
      <Row>
        <Col md={6}>
          <Card className="contato-card">
            <Card.Body>
              <Card.Title className="contato-title">Unidade Saúde</Card.Title>
              <Card.Text>
                Avenida Itaboraí, 614, Bosque da Saúde, São Paulo - SP
              </Card.Text>
              <Card.Text className="spaced-text">
                Telefone: (11) 5071-5529
              </Card.Text>
              <Card.Text>
                <a href={`https://wa.me/${whatsappSaude}?text=Olá!%20Vim%20pelo%20Site%20e%20gostaria%20de%20mais%20informações%20sobre%20a%20Unidade%20da%20Saúde.`}className="btn-saiba-mais"><FaWhatsapp size={25} /> (11) 98986-5875</a>
              </Card.Text>
              <Card.Link href="https://www.google.com/maps/dir//Rubel+Academia+-+Av.+Itabora%C3%AD,+614+-+Bosque+da+Sa%C3%BAde,+S%C3%A3o+Paulo+-+SP,+04135-000/@-23.6082699,-46.6695021,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94ce5bb3f438fcff:0x11c92c92eb8867df!2m2!1d-46.6282995!2d-23.6083545?entry=ttu" target="_blank">
                Ver no Google Maps
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="contato-card">
            <Card.Body>
              <Card.Title className="contato-title">Unidade Campo Belo</Card.Title>
              <Card.Text>
                Rua Cristóvão Pereira, 1616, Campo Belo, São Paulo - SP
              </Card.Text>
              <Card.Text className="spaced-text">
                Telefone: (11) 5041-3272
              </Card.Text>
              <Card.Text>
              <a href={`https://wa.me/${whatsappCampoBelo}?text=Olá!%20Vim%20pelo%20Site%20e%20gostaria%20de%20mais%20informações%20sobre%20a%20Unidade%20do%20Campo%20Belo.`} className="btn-saiba-mais"><FaWhatsapp size={25} /> (11) 98980-9250</a>
              </Card.Text>
              <Card.Link href="https://www.google.com/maps/dir//Rubel+Academia+-+Unidade+Campo+Belo+-+Rua+Crist%C3%B3v%C3%A3o+Pereira+-+Campo+Belo,+S%C3%A3o+Paulo+-+SP/@-23.6251492,-46.7512661,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x94ce5a77bb7746e7:0xc6774f57a758a4a3!2m2!1d-46.6688797!2d-23.6253217?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                Ver no Google Maps
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Contatos;