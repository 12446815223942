import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import Header from './components/header/index';
import Home from './components/home/index';
import Sobre from './components/sobre/index';
import Contatos from './components/contato/index';
import Footer from './components/footer/index';
import Treino from './components/treino/index';
import Convenios from './components/convenios/index';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Header />
    <Sobre  />
    <Treino />
    <Home />
    <Convenios />
    <Contatos />
    <Footer />
  </React.StrictMode>
);

reportWebVitals();