import React from 'react';
import './Sobre.css';
import { Container, Row, Col, Carousel, CarouselItem } from 'react-bootstrap';
import unidadeSaude from '../../static/img/academiaSaude.jpg';
import unidadeCb from '../../static/img/academiaCampoBelo.jpg'

function Sobre() {
  return (
    <Container id="sobre" className="sobre-container">
      <Row>
        <Col md={6} className="sobre-text-col">
          <h2 className="sobre-title">Um Pouco da Nossa História</h2>
          <p>
            Há 17 anos presente na cidade de São Paulo, a Rubel Academia foi estabelecida através de um trabalho sério e inovador no segmento aquático e fitness.
            Nossa metodologia utiliza a atividade física orientada e o excelente atendimento de nossos departamentos para a cada dia satisfazer às necessidades de nosso público.
            Hoje contamos com duas unidades na rede.
          </p>
        </Col>
        <Col md={6} className="sobre-image-col">
          <Carousel className='carousel'>
            <CarouselItem>
            <img src={unidadeSaude} 
            alt="Imagem da Academia" 
            className="sobre-image" />
            <Carousel.Caption>
              <h3>Rubel Saúde</h3>
              <p>Venha conhecer nossa unidade na Saúde.</p>
            </Carousel.Caption>
            </CarouselItem>
            <CarouselItem>
            <img src={unidadeCb} 
            alt="Imagem da Academia" 
            className="sobre-image" />
            <Carousel.Caption>
              <h3>Rubel Campo Belo</h3>
              <p>Venha conhecer nossa unidade no Campo Belo.</p>
            </Carousel.Caption>
            </CarouselItem>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default Sobre;