import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaClock, FaFire, } from 'react-icons/fa';
import './Treino.css';
// import allianceBjj from '../../static/img/jiujitsu.png';
import natacao from '../../static/img/natacao.png';
import musculacao from '../../static/img/musc.png';
import muitoMais from '../../static/img/muito_mais.png';

function Treino() { 
  const whatsappNumber = "5511989865875";

  return (
    <Container id="treino">
      <div className="section-title">
        <h2>Explore Nossos Treinos</h2>
        <p>Escolha entre uma variedade de atividades que atendem a todos os objetivos e níveis de condicionamento físico.</p>
      </div>
      <Row className="justify-content-center">
        {/** Mapeia os treinos para evitar repetição de código */}
        {[
          {
            img: natacao,
            title: 'Natação',
            description: 'Aulas de natação em vários níveis, desde bebê até adulto, e também hidroginástica.',
            duration: '45 min',
            intensity: 'Alta',
            text: 'Olá!%20Vim%20pelo%20Site%20e%20gostaria%20de%20mais%20informações%20sobre%20o%20treino%20de%20Natação.'
          },
          {
            img: musculacao,
            title: 'Musculação',
            description: 'Treino intenso com exercícios funcionais e variados para alto desempenho.',
            duration: '60 min',
            intensity: 'Alta',
            text: 'Olá!%20Vim%20pelo%20Site%20e%20gostaria%20de%20mais%20informações%20sobre%20o%20treino%20de%20Musculação.'
          },
          // {
          //   img: allianceBjj,
          //   title: 'Alliance Jiu Jitsu',
          //   description: 'Treinamento focado em defesa pessoal, técnicas de combate, resistência e disciplina.',
          //   duration: '60 min',
          //   intensity: 'Muito alta',
          //   text: 'Olá!%20Vim%20pelo%20Site%20e%20gostaria%20de%20mais%20informações%20sobre%20o%20treino%20de%20Jiu%20Jitsu.'
          // },
          {
            img: muitoMais,
            title: 'Temos muito mais',
            description: 'Descubra a variedade de atividades que temos para você. Não perca a oportunidade de viver a melhor experiência Rubel!',
            duration: '',
            intensity: '',
            text: 'Olá!%20Vim%20pelo%20Site%20e%20gostaria%20de%20mais%20informações%20sobre%20as%20outras%20atividades%20da%20academia.'
          }
        ].map((treino, index) => (
          <Col md={3} key={index}>
            <Card className="treino-card">
              <Card.Img variant="top" src={treino.img} alt={treino.title}/>
              <Card.Body>
                <Card.Title>{treino.title}</Card.Title>
                <Card.Text className="card-description">
                  {treino.description}
                </Card.Text>
                {treino.duration && (
                  <Card.Text>
                    <div className="card-info">
                      <FaClock /> {treino.duration}
                    </div>
                    <div className="card-info">
                      <FaFire /> {treino.intensity}
                    </div>
                  </Card.Text>
                )}
                <a href={`https://wa.me/${whatsappNumber}?text=${treino.text}`} className="btn-saiba-mais">
                   Saiba Mais 
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Treino;